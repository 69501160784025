import React, { useState, useEffect } from 'react';
import { IconButton, TextField} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import { BASE_URL } from '../../components/CommonExport';
import Component from '../../components/Component.js';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link } from 'react-router-dom';

const UrlHistory = () => {
    const [urls, setUrls] = useState([]);
    const [limit, setLimit] = useState(20);
    const [offset, setOffset] = useState(0);
    const [start_date, setStartDate] = useState(''); // Start date state
    const [end_date, setEndDate] = useState(''); // End date state

    useEffect(() => {
        getUrls();
    }, [start_date, end_date, limit, offset]); // Refetch data whenever these change
    const handleNext = () => {
        const newOffset = offset + 20;
        setOffset(newOffset);
    };

    const handlePrevious = () => {
        const newOffset = Math.max(0, offset - 20);
        setOffset(newOffset);
    };
    async function getUrls() {
        try {
            const response = await axios.get(
                `https://api.dugoutlive.com/redirect`,
                {
                    params: {
                        limit,
                        offset,
                        ...(start_date && { start_date }),
                        ...(end_date && { end_date }),
                    },
                }
            );
            setUrls(response.data.data || []);
        } catch (err) {
            console.log(err);
        }
    }

    const handleStartDateChange = (event) => {
        setStartDate(event.target.value);
    };

    const handleEndDateChange = (event) => {
        setEndDate(event.target.value);
    };

    const columns = [
        { field: 'title', headerName: 'Title', width: 200 },
        { field: 'url', headerName: 'Deep Link Url', width: 300 },
        {field: 'created_at', headerName: 'Created At', width: 200},
        {field: 'opened_count', headerName: 'Open Count', width: 100},
        {field: 'copy_url', headerName: 'Copy Url', width: 300},
    ];

    return (
        <Component>
            <div className="page-body">
                <div className="container-fluid">
                    <div className="page-header">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="d-flex align-items-center">
                                    <IconButton style={{ background: '#FFFFFF' }} component={Link} to="/">
                                        <ArrowBackIcon />
                                    </IconButton>
                                    <h3 style={{ marginLeft: '20px' }}>Copy Url Logs</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid">
                    <div className="row mb-3">
                        <div className="col-sm-6">
                            <TextField
                                label="Start Date"
                                type="date"
                                value={start_date}
                                onChange={handleStartDateChange}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                fullWidth
                            />
                        </div>
                        <div className="col-sm-6">
                            <TextField
                                label="End Date"
                                type="date"
                                value={end_date}
                                onChange={handleEndDateChange}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                fullWidth
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-body">
                                    <div style={{ height: 400, width: '100%' }}>
                                        <DataGrid
                                            rows={urls}
                                            columns={columns}
                                            pageSize={5}
                                            rowsPerPageOptions={[5]}
                                        />
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'center', gap: '10px', margin: '20px 0' }}>
                                        <button onClick={handlePrevious} disabled={offset === 0}>
                                            Previous
                                        </button>
                                        <span>Page: {(offset / limit) + 1}</span>
                                        <button onClick={handleNext}>
                                            Next
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Component>
    );
};

export default UrlHistory;
