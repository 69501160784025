import React, { useEffect, useState } from 'react';
import Component from '../../components/Component';
import { BASE_URL, handleDynamicRequest } from '../../components/CommonExport';
import { DataGrid } from '@mui/x-data-grid';
import { Typography, Paper, TextField, IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Autocomplete, Grid, Box, CircularProgress } from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';
import { KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import Papa from 'papaparse';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import axios from 'axios';

function Users() {
  const [tableData, setTableData] = useState([]);
  const [tableDisplay, setTableDisplay] = useState(false);
  const [dropdownUserData, setDropdownUserData] = useState([]);
  const [dropdownTrackingUserData, setDropdownTrackingUserData] = useState([]);
  const [dropdownMatchData, setDropdownMatchData] = useState([]);
  const [competitionFilter, setCompetitionFilter] = useState(null);
  const [usernameFilter, setUsernameFilter] = useState('');
  const [fromDateFilter, setFromDateFilter] = useState('');
  const [toDateFilter, setToDateFilter] = useState('');
  const [matchFilter, setMatchFilter] = useState(null);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [usersAdded, setUsersAdded] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [deleteUserId, setDeleteUserId] = useState(null);
  const [sortBy, setSortBy] = useState('');
  const [sortOrder, setSortOrder] = useState('asc');
  const [competitionData, setCompetitionData] = useState([]);
  const [competitionFilterId, setCompetitionFilterId] = useState(null);
  const [loading, setLoading] = useState(false)

  // Fetch users for the dropdown from the API
  useEffect(() => {
    fetch(`${BASE_URL}/tracking/trackingusers`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJhcGkuZGV2Lnh5ei5jb20iLCJhdWQiOiJ4eXouY29tIiwic3ViIjoiMTJiNTBhNDgtNWU5ZS00MzNmLTgyNmMtMTJhNmQ3MmM5N2NkIiwiaWF0IjoxNzExNDU2OTEzLCJleHAiOjE3NDI1NjA5MTMsInBybSI6IjMwZDQyNzg2ZGMyNTU3MDBkZDkzNzViMWQ4ZDYwN2E2MzBhYzViMzJhNWU1MjUxODQ2ZDQyNzE1YjBlN2Y3YTllZjczZjAzZDk1MzI3OGQ5NTdjOTVmOGE2YTVkZjk1MTM3MDc4ZTY4ODNlNTU3MjUzODczZjViYjNjMDZiNzZmIn0.msUlenclhnnuqwdU9R5HL32rYnUVmdyITkPlzSWDcio'
      },
    })
      .then(response => response.json())
      .then(data => {
        setDropdownTrackingUserData(data.data.users.rows);
      })
      .catch(error => console.error('Error fetching users for dropdown:', error));
  }, []);

  useEffect(() => {
    fetch(`${BASE_URL}/tracking/users`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJhcGkuZGV2Lnh5ei5jb20iLCJhdWQiOiJ4eXouY29tIiwic3ViIjoiMTJiNTBhNDgtNWU5ZS00MzNmLTgyNmMtMTJhNmQ3MmM5N2NkIiwiaWF0IjoxNzExNDU2OTEzLCJleHAiOjE3NDI1NjA5MTMsInBybSI6IjMwZDQyNzg2ZGMyNTU3MDBkZDkzNzViMWQ4ZDYwN2E2MzBhYzViMzJhNWU1MjUxODQ2ZDQyNzE1YjBlN2Y3YTllZjczZjAzZDk1MzI3OGQ5NTdjOTVmOGE2YTVkZjk1MTM3MDc4ZTY4ODNlNTU3MjUzODczZjViYjNjMDZiNzZmIn0.msUlenclhnnuqwdU9R5HL32rYnUVmdyITkPlzSWDcio'
      },
    })
      .then(response => response.json())
      .then(data => {
        setDropdownUserData(data.data.users.rows);
      })
      .catch(error => console.error('Error fetching users for dropdown:', error));
  }, []);

  // Fetch matches for the dropdown from the API
  useEffect(() => {
    const requestBody = {};
    if (fromDateFilter) {
      requestBody.startDate = fromDateFilter;
    }
    if (toDateFilter) {
      requestBody.endDate = toDateFilter;
    }
    if (competitionFilterId) {
      requestBody.cid = competitionFilterId;
    }

    fetch(`${BASE_URL}/tracking/matches-by-cid`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJhcGkuZGV2Lnh5ei5jb20iLCJhdWQiOiJ4eXouY29tIiwic3ViIjoiMTJiNTBhNDgtNWU5ZS00MzNmLTgyNmMtMTJhNmQ3MmM5N2NkIiwiaWF0IjoxNzExNDU2OTEzLCJleHAiOjE3NDI1NjA5MTMsInBybSI6IjMwZDQyNzg2ZGMyNTU3MDBkZDkzNzViMWQ4ZDYwN2E2MzBhYzViMzJhNWU1MjUxODQ2ZDQyNzE1YjBlN2Y3YTllZjczZjAzZDk1MzI3OGQ5NTdjOTVmOGE2YTVkZjk1MTM3MDc4ZTY4ODNlNTU3MjUzODczZjViYjNjMDZiNzZmIn0.msUlenclhnnuqwdU9R5HL32rYnUVmdyITkPlzSWDcio'
      },
      body: JSON.stringify(requestBody)
    })
      .then(response => response.json())
      .then(data => {
        console.log('matchData', data)
        setDropdownMatchData(data.data.matches);
      })
      .catch(error => console.error('Error fetching matches for dropdown:', error));
  }, [fromDateFilter, toDateFilter, competitionFilterId]);


  useEffect(() => {
    // Prepare request body
    const requestBody = {};

    // Add start and end dates to request body if selected
    if (fromDateFilter) {
      requestBody.startDate = fromDateFilter;
    }
    if (toDateFilter) {
      requestBody.endDate = toDateFilter;
    }
    // Add username filter if selected
    if (usernameFilter) {
      requestBody.user_id = usernameFilter.user_id;
    }

    // Add match filter if selected
    if (matchFilter && matchFilter.id !== 'all') {
      requestBody.match_id = matchFilter.match_id;
    }

    if (competitionFilterId) {
      requestBody.cid = competitionFilterId;
    }
    setLoading(true)
    fetch(`${BASE_URL}/tracking/fetchTrackingUserData`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJhcGkuZGV2Lnh5ei5jb20iLCJhdWQiOiJ4eXouY29tIiwic3ViIjoiMTJiNTBhNDgtNWU5ZS00MzNmLTgyNmMtMTJhNmQ3MmM5N2NkIiwiaWF0IjoxNzExNDU2OTEzLCJleHAiOjE3NDI1NjA5MTMsInBybSI6IjMwZDQyNzg2ZGMyNTU3MDBkZDkzNzViMWQ4ZDYwN2E2MzBhYzViMzJhNWU1MjUxODQ2ZDQyNzE1YjBlN2Y3YTllZjczZjAzZDk1MzI3OGQ5NTdjOTVmOGE2YTVkZjk1MTM3MDc4ZTY4ODNlNTU3MjUzODczZjViYjNjMDZiNzZmIn0.msUlenclhnnuqwdU9R5HL32rYnUVmdyITkPlzSWDcio'
      },
      body: JSON.stringify(requestBody)
    })
      .then(response => response.json())
      .then(data => {
        console.log(data)
        setTableData(data.data.users);
        setFilteredData(data.data.users)
        setLoading(false)
      })
      .catch(error => console.error('Error fetching table data:', error));
  }, [fromDateFilter, toDateFilter, usernameFilter, matchFilter, competitionFilterId]); // Fetch data whenever any of the filters change

  const handleAddUsers = () => {
    const selectedUserData = selectedUsers.map(user => ({ id: user.id, username: user.username, name: user.name }));

    const requestBody = {
      users: selectedUserData,
    };

    if (fromDateFilter) {
      requestBody.startDate = fromDateFilter;
    }
    if (toDateFilter) {
      requestBody.endDate = toDateFilter;
    }

    fetch(`${BASE_URL}/tracking/addUsersToView`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJhcGkuZGV2Lnh5ei5jb20iLCJhdWQiOiJ4eXouY29tIiwic3ViIjoiMTJiNTBhNDgtNWU5ZS00MzNmLTgyNmMtMTJhNmQ3MmM5N2NkIiwiaWF0IjoxNzExNDU2OTEzLCJleHAiOjE3NDI1NjA5MTMsInBybSI6IjMwZDQyNzg2ZGMyNTU3MDBkZDkzNzViMWQ4ZDYwN2E2MzBhYzViMzJhNWU1MjUxODQ2ZDQyNzE1YjBlN2Y3YTllZjczZjAzZDk1MzI3OGQ5NTdjOTVmOGE2YTVkZjk1MTM3MDc4ZTY4ODNlNTU3MjUzODczZjViYjNjMDZiNzZmIn0.msUlenclhnnuqwdU9R5HL32rYnUVmdyITkPlzSWDcio'
      },
      body: JSON.stringify(requestBody)
    })
      .then(response => {
        if (response.ok) {
          setUsersAdded(true);
        }
      })
      .catch(error => console.error('Error adding users:', error));
  };

  useEffect(() => {
    if (usersAdded) {
      window.location.reload();
    }
  }, [usersAdded]);

  // Format date function
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', minute: 'numeric' };
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const formattedMonth = date.getUTCMonth();
    const formattedDate = date.getUTCDate();
    const formattedYear = date.getUTCFullYear();
    const formattedTime = (date.getUTCHours() % 12 || 12) + ':' + (date.getUTCMinutes() < 10 ? '0' : '') + date.getUTCMinutes() + ' ' + (date.getUTCHours() < 12 ? 'am' : 'pm') + ' IST';
    return months[formattedMonth] + ' ' + formattedDate + ' ' + formattedYear + ' ' + formattedTime;
  };

  useEffect(() => {
    axios.get(`${BASE_URL}/admin/competitions/get_competitions?start_date=${fromDateFilter}&date_end=${toDateFilter}`).then((result) => {
      const competitionData = result.data.data.competitions
      console.log(competitionData)
      setCompetitionData(competitionData)
    }).catch((error) => {
      console.log(error)
    })
  }, [fromDateFilter, toDateFilter])



  // Function to handle delete confirmation
  const handleDeleteConfirmation = (userId) => {
    console.log(userId, 'userId')
    setDeleteUserId(userId);
    setConfirmDelete(true);
  };

  const handleDeleteUser = (userId) => {
    // Call API to delete user
    console.log(userId)
    fetch(`${BASE_URL}/tracking/delete`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJhcGkuZGV2Lnh5ei5jb20iLCJhdWQiOiJ4eXouY29tIiwic3ViIjoiMTJiNTBhNDgtNWU5ZS00MzNmLTgyNmMtMTJhNmQ3MmM5N2NkIiwiaWF0IjoxNzExNDU2OTEzLCJleHAiOjE3NDI1NjA5MTMsInBybSI6IjMwZDQyNzg2ZGMyNTU3MDBkZDkzNzViMWQ4ZDYwN2E2MzBhYzViMzJhNWU1MjUxODQ2ZDQyNzE1YjBlN2Y3YTllZjczZjAzZDk1MzI3OGQ5NTdjOTVmOGE2YTVkZjk1MTM3MDc4ZTY4ODNlNTU3MjUzODczZjViYjNjMDZiNzZmIn0.msUlenclhnnuqwdU9R5HL32rYnUVmdyITkPlzSWDcio'

      },
      body: JSON.stringify({ user_id: userId }) // Include user ID in the request body
    })
      .then(response => {
        if (response.ok) {
          // Reload the page
          window.location.reload();
        }
      })
      .catch(error => console.error('Error deleting user:', error));
  };


  const handleSort = (column) => {
    if (sortBy === column) {
      // If already sorting by this column, reverse the order
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      // If sorting by a new column, set it as the new sorting column
      setSortBy(column);
      setSortOrder('asc');
    }
  };

  useEffect(() => {
    if (tableData && tableData.length > 0) {
      setTableDisplay(true);
    }
  }, [tableData]);
  const exportToCSV = () => {
    const csvData = filteredData.map(item => ({
      Username: item.username,
      Name: item.name,
      Views: item.views,
      totalViews: item.totalViews,
      Posts: item.posts,
      Ratings: item.ratings,
      Followers: item.followers,
      Likes: item.likes,
      Comments: item.comments,
      Engagement: item.engagement,
      commentsByUser: item.commentsByUser,
    }));

    const csv = Papa.unparse(csvData);


    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', 'data_export.csv');
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };


  const columns = [
    { field: 'username', headerName: 'Username', flex: 1 },
    { field: 'views', headerName: 'Views', flex: 1, type: 'number' },
    { field: 'totalViews', headerName: 'Total Views', flex: 1, type: 'number' },
    { field: 'posts', headerName: 'Posts', flex: 1, type: 'number' },
    { field: 'ratings', headerName: 'Ratings', flex: 1, type: 'number' },
    { field: 'followers', headerName: 'Followers', flex: 1, type: 'number' },
    { field: 'likes', headerName: 'Likes', flex: 1, type: 'number' },
    { field: 'comments', headerName: 'Comments', flex: 1, type: 'number' },
    { field: 'commentsByUser', headerName: 'Comments by user', flex: 1, type: 'number' },
    { field: 'engagement', headerName: 'Engagement', flex: 1, type: 'number' },
    // { field: 'combinedScore', headerName: 'Combined Score', flex: 1 },    
    {
      field: 'action',
      headerName: 'Action',
      flex: 1,
      renderCell: (params) => (
        <IconButton onClick={() => handleDeleteConfirmation(params.row.id)}>
          <DeleteIcon />
        </IconButton>
      ),
    },
  ];
  const [searchText, setSearchText] = React.useState('');
  const [filteredData, setFilteredData] = React.useState(tableData);
  const [sortModel, setSortModel] = React.useState([]);

  const calculateTotals = (data) => {
    return {
      totalViews: data.reduce((acc, row) => acc + Number(row.views || 0), 0),
      totalPosts: data.reduce((acc, row) => acc + Number(row.posts || 0), 0),
      totalRatings: data.reduce((acc, row) => acc + Number(row.ratings || 0), 0),
      totalFollowers: data.reduce((acc, row) => acc + Number(row.followers || 0), 0),
      totalLikes: data.reduce((acc, row) => acc + Number(row.likes || 0), 0),
      totalComments: data.reduce((acc, row) => acc + Number(row.comments || 0), 0),
      totalEngagement: data.reduce((acc, row) => acc + Number(row.engagement || 0), 0).toFixed(2),
      totalCombinedScore: data.reduce((acc, row) => acc + Number(row.combinedScore || 0), 0)
    };
  };
  const totals = calculateTotals(filteredData);

  // console.log(totalViews, totalPosts, totalRatings, totalFollowers, totalLikes, totalComments, totalEngagement, totalCombinedScore)
  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchText(value);
    const filteredRows = tableData.filter((row) => {
      return Object.values(row).some((field) =>
        String(field).toLowerCase().includes(value)
      );
    });
    setFilteredData(filteredRows);
  };


  console.log(fromDateFilter)
  return (
    <Component>
      {/* {alertMessage.error && <div className="error">{alertMessage.error}</div>}
      {alertMessage.success && <div className="success">{alertMessage.success}</div>} */}
      <div class="page-body">
        <div class="container-fluid">
          <div class="page-header">
            <div class="row">
              <div className="col-sm-12">
                <div className="d-flex align-items-center ">
                  <IconButton style={{ background: '#FFFFFF' }} component={Link} to="/content-dashboard">
                    <ArrowBackIcon />
                  </IconButton>
                  <h3 style={{ marginLeft: '20px' }}>User Dashboard</h3>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div class="container-fluid">
          <div class="row">

            <div class="col-sm-12">
              <div class="card">
                <div class="card-body">

                  <Grid style={{ marginBottom: '16px' }} container spacing={2} alignItems="center">

                    <Grid item xs={10}>
                      <Autocomplete
                        multiple
                        id="user-selector"
                        options={dropdownUserData}
                        getOptionLabel={(option) => `${option.id} - ${option.username} - ${option.name || ''} - ${option.email || ''} - ${option.phone || ''}`}
                        onChange={(event, newValue) => setSelectedUsers(newValue)}
                        renderInput={(params) => <TextField {...params} label="Select Users" variant="outlined" />}
                        fullWidth
                      />
                    </Grid>

                    <Grid item xs={2}>
                      <Button style={{ width: '100%', height: '100%' }} variant="contained" color="primary" onClick={handleAddUsers} fullWidth>
                        Add Selected Users
                      </Button>
                    </Grid>
                  </Grid>
                  {usersAdded && (
                    <Typography variant="subtitle1" gutterBottom>
                      Following users added to tracker dashboard.
                    </Typography>
                  )}


                  <Grid container spacing={2} sx={{ marginBottom: '20px' }}>

                    <Grid item xs={12} sm={6} md={4}>
                      <Autocomplete
                        id="username-filter"
                        options={dropdownTrackingUserData}
                        getOptionLabel={(option) => `${option.user_id} - ${option.username} - ${option.name}`}
                        value={usernameFilter}
                        onChange={(event, newValue) => setUsernameFilter(newValue)}
                        renderInput={(params) => <TextField {...params} label="Filter by Username" variant="outlined" fullWidth />}
                        sx={{ backgroundColor: 'white' }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        label="From Date"
                        type="date"
                        variant="outlined"
                        fullWidth
                        value={fromDateFilter}
                        onChange={(e) => setFromDateFilter(e.target.value)}
                        InputLabelProps={{ shrink: true }}
                        sx={{ backgroundColor: 'white' }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        label="To Date"
                        type="date"
                        variant="outlined"
                        fullWidth
                        value={toDateFilter}
                        onChange={(e) => setToDateFilter(e.target.value)}
                        InputLabelProps={{ shrink: true }}
                        sx={{ backgroundColor: 'white' }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <Autocomplete
                        id="competition-filter"
                        options={competitionData}
                        getOptionLabel={(option) => `${option?.cid || ' '} - ${option.title || ' '} - ${option.game_format || ''}`}
                        value={competitionFilter}
                        onChange={(event, newValue) => {
                          setCompetitionFilterId(newValue?.cid);
                          setCompetitionFilter(newValue);
                        }}
                        renderInput={(params) => {
                          return <TextField {...params} label="Select Competition" variant="outlined" fullWidth />
                        }}
                        sx={{ backgroundColor: 'white' }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <Autocomplete
                        id="match-filter"
                        options={[{ id: 'all', name: 'All' }, ...dropdownMatchData]}
                        getOptionLabel={(option) => `${option.match_id || ' '}, ${option.title || ' '}, ${option.subtitle || ''}, ${formatDate(option.date_start_ist) || ' '}`}
                        value={matchFilter}
                        onChange={(event, newValue) => {
                          setMatchFilter(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} label="Select Match" variant="outlined" fullWidth />}
                        sx={{ backgroundColor: 'white' }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <TextField
                        fullWidth
                        label="Search"
                        variant="outlined"
                        value={searchText}
                        onChange={handleSearch}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        style={{ marginTop: '16px' }}
                        variant="contained"
                        color="primary"
                        onClick={exportToCSV}
                      >
                        Export to CSV
                      </Button>
                    </Grid>
                  </Grid>
                  <Box
                    sx={{
                      backgroundColor: '#f9f9f9',
                      border: '1px solid #ddd',
                      padding: '16px',
                      borderRadius: '8px',
                      marginBottom: '20px'
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={6} sm={4}><Typography>Total Views: {totals.totalViews}</Typography></Grid>
                      <Grid item xs={6} sm={4}><Typography>Total Posts: {totals.totalPosts}</Typography></Grid>
                      <Grid item xs={6} sm={4}><Typography>Total Ratings: {totals.totalRatings}</Typography></Grid>
                      <Grid item xs={6} sm={4}><Typography>Total Likes: {totals.totalLikes}</Typography></Grid>
                      <Grid item xs={6} sm={4}><Typography>Total Comments: {totals.totalComments}</Typography></Grid>
                      <Grid item xs={6} sm={4}><Typography>Total Engagement: {totals.totalEngagement}</Typography></Grid>
                    </Grid>
                  </Box>
                  {loading ? (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                      }}
                    >
                      <CircularProgress />
                    </Box>
                  ) : (
                  <DataGrid
                    rows={filteredData}
                    columns={columns}
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                    sortingOrder={['asc', 'desc']}
                    sortModel={sortModel}
                    onSortModelChange={(model) => setSortModel(model)}
                    getRowId={(row) => row.id}
                  />
                  )}
                  <Dialog
                    open={confirmDelete}
                    onClose={() => setConfirmDelete(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete this user?
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={() => setConfirmDelete(false)} color="primary">
                        Cancel
                      </Button>
                      <Button onClick={handleDeleteUser} color="primary" autoFocus>
                        Confirm
                      </Button>
                    </DialogActions>
                  </Dialog>
                </div>


              </div>
            </div>

          </div>
        </div>

      </div>
    </Component>
  );
}

export default Users;


