import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BASE_URL } from '../../components/CommonExport.js';
import { DataGrid } from '@mui/x-data-grid';
import { TextField, Box, Typography, Paper, CircularProgress, Alert, IconButton, Grid } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link } from 'react-router-dom';
import Component from '../../components/Component.js';

const TopPosts = () => {
    const [topPosts, setTopPosts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [selectedDate, setSelectedDate] = useState(() => {
        const today = new Date();
        return today.toISOString().split('T')[0]; // Format YYYY-MM-DD
    });

    useEffect(() => {
        if (!selectedDate) return;

        const fetchUsers = async () => {
            setLoading(true);
            setError(null);
            try {
                const response = await axios.get(`${BASE_URL}/admin/feeds/top/posts?date=${selectedDate}`);
                setTopPosts(response.data.data.result);
            } catch (err) {
                setError(err.response?.data?.message || 'Failed to fetch data');
            } finally {
                setLoading(false);
            }
        };

        fetchUsers();
    }, [selectedDate]);

    const handleDateChange = (event) => {
        setSelectedDate(event.target.value);
    };

    const columns = [
        {field: 'content', headerName: 'Content', width: 200},
        {field: 'likes_count', headerName: 'Total Likes', width: 200},
        {field: 'comments_count', headerName: 'Total Comments', width: 200},
        {field: 'total_engagement', headerName: 'Total Engagement', width: 200},
        {field: 'total_feed_views', headerName: 'Total Feed Views', width: 200},
        {field: 'unique_feed_views', headerName: 'Unique Feed Views', width: 200},
        {field: 'username', headerName: 'Username', width: 200},  
    ];

    return (
        <Component>
            <div className="page-body">
                <div className="container-fluid">
                    <div className="page-header">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="d-flex align-items-center">
                                    <IconButton style={{ background: '#FFFFFF' }} component={Link} to="/">
                                        <ArrowBackIcon />
                                    </IconButton>
                                    <h3 style={{ marginLeft: '20px' }}>Top Posts</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-body">
                                    <Paper elevation={3} style={{ padding: '20px' }}>
                                        <Box mb={3} style={{ maxWidth: '300px' }}>
                                            <TextField
                                                label="Select Date"
                                                type="date"
                                                variant="outlined"
                                                fullWidth
                                                value={selectedDate}
                                                onChange={handleDateChange}
                                                helperText="Choose a date in 'yyyy-mm-dd' format"
                                            />
                                        </Box>

                                        {loading ? (
                                            <Box display="flex" justifyContent="center" mt={3}>
                                                <CircularProgress />
                                            </Box>
                                        ) : error ? (
                                            <Alert severity="error" mt={3}>
                                                {error}
                                            </Alert>
                                        ) : (
                                            <Box mt={3} style={{ height: 400 }}>
                                                <DataGrid
                                                    rows={topPosts}
                                                    columns={columns}
                                                    pageSize={5}
                                                    rowsPerPageOptions={[5]}
                                                    disableSelectionOnClick
                                                />
                                            </Box>
                                        )}
                                    </Paper>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Component>
    );
};

export default TopPosts;
