import React, { useState, useEffect, useRef } from 'react';
import Component from '../../components/Component'
import { BASE_URL, handleDynamicRequest } from '../../components/CommonExport';
import 'datatables.net-dt/css/dataTables.dataTables.min.css';
import 'datatables.net-dt/css/dataTables.dataTables.css';
import $ from 'jquery';
import 'datatables.net';
import { TextField, Grid, Autocomplete, IconButton, CircularProgress, Box, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import axios from 'axios';

function Players() {
    const [playerData, setPlayerData] = useState([]);
    const [matchData, setMatchData] = useState([]);
    const [searchText, setSearchText] = React.useState('');
    const [filteredData, setFilteredData] = React.useState(playerData);
    const [loading, setLoading] = useState(false)


    // useEffect(() => {
    //     fetch(`${BASE_URL}/tracking/fetchTrackingPlayers`, {
    //         headers: {
    //             'Content-Type': 'application/json',
    //             'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJhcGkuZGV2Lnh5ei5jb20iLCJhdWQiOiJ4eXouY29tIiwic3ViIjoiMTJiNTBhNDgtNWU5ZS00MzNmLTgyNmMtMTJhNmQ3MmM5N2NkIiwiaWF0IjoxNzExNDU2OTEzLCJleHAiOjE3NDI1NjA5MTMsInBybSI6IjMwZDQyNzg2ZGMyNTU3MDBkZDkzNzViMWQ4ZDYwN2E2MzBhYzViMzJhNWU1MjUxODQ2ZDQyNzE1YjBlN2Y3YTllZjczZjAzZDk1MzI3OGQ5NTdjOTVmOGE2YTVkZjk1MTM3MDc4ZTY4ODNlNTU3MjUzODczZjViYjNjMDZiNzZmIn0.msUlenclhnnuqwdU9R5HL32rYnUVmdyITkPlzSWDcio'
    //         },
    //     })
    //         .then(response => response.json())
    //         .then(data => {
    //             setPlayerData(data.data.users);
    //             setFilteredData(data.data.users);
    //         })
    //         .catch(error => console.error('Error fetching matches for dropdown:', error));
    // }, []);

    const playerNames = playerData?.map(player => player.title);
    const [playerNameFilter, setPlayerNameFilter] = useState('');
    const [dateFromFilter, setDateFromFilter] = useState('');
    const [dateToFilter, setDateToFilter] = useState('');
    const [matchFilter, setMatchFilter] = useState(null);
    const [competitionData, setCompetitionData] = useState([]);
    const [competitionFilterId, setCompetitionFilterId] = useState(null);
    const [competitionFilter, setCompetitionFilter] = useState(null);


    // Filter player data based on filter values
    const filteredPlayerData = playerData?.filter(player => {
        // Filter by player name
        if (playerNameFilter && !player?.title.toLowerCase().includes(playerNameFilter.toLowerCase())) {
            return false;
        }
        // Find the corresponding match data
        const match = matchData.find(match => match.id === player.matchId);
        if (!match) {
            return false; // Return false if no match is found for the player
        }
        // Filter by match date range
        if (dateFromFilter && new Date(match.date) < new Date(dateFromFilter)) {
            return false;
        }
        if (dateToFilter && new Date(match.date) > new Date(dateToFilter)) {
            return false;
        }
        // Filter by match name
        if (matchFilter && matchFilter.id !== 'all' && player.matchId !== parseInt(matchFilter.id)) {
            return false;
        }
        return true;
    });


    useEffect(() => {
        // Prepare request body
        const requestBody = {};

        // Add start and end dates to request body if selected
        if (dateFromFilter) {
            requestBody.startDate = dateFromFilter;
        }
        if (dateToFilter) {
            requestBody.endDate = dateToFilter;
        }
        if (playerNameFilter) {
            requestBody.playerName = playerNameFilter;
        }
        if (competitionFilterId) {
            requestBody.cid = competitionFilterId;
        }

        if (matchFilter && matchFilter.id !== 'all') {
            requestBody.match_id = matchFilter.match_id;
        }
        setLoading(true)
        fetch(`${BASE_URL}/tracking/fetchTrackingPlayers`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJhcGkuZGV2Lnh5ei5jb20iLCJhdWQiOiJ4eXouY29tIiwic3ViIjoiMTJiNTBhNDgtNWU5ZS00MzNmLTgyNmMtMTJhNmQ3MmM5N2NkIiwiaWF0IjoxNzExNDU2OTEzLCJleHAiOjE3NDI1NjA5MTMsInBybSI6IjMwZDQyNzg2ZGMyNTU3MDBkZDkzNzViMWQ4ZDYwN2E2MzBhYzViMzJhNWU1MjUxODQ2ZDQyNzE1YjBlN2Y3YTllZjczZjAzZDk1MzI3OGQ5NTdjOTVmOGE2YTVkZjk1MTM3MDc4ZTY4ODNlNTU3MjUzODczZjViYjNjMDZiNzZmIn0.msUlenclhnnuqwdU9R5HL32rYnUVmdyITkPlzSWDcio'

            },
            body: JSON.stringify(requestBody)
        })
            .then(response => response.json())
            .then(data => {
                setLoading(false)
                setPlayerData(data.data.users);
                setFilteredData(data.data.users);
            })
            .catch(error => console.error('Error fetching table data:', error));
    }, [dateFromFilter, dateToFilter, matchFilter, playerNameFilter, competitionFilterId]);

    useEffect(() => {
        axios.get(`${BASE_URL}/admin/competitions/get_competitions?start_date=${dateFromFilter}&end_date=${dateToFilter}`).then((result) => {
            const competitionData = result.data.data.competitions
            console.log(competitionData)
            setCompetitionData(competitionData)
        }).catch((error) => {
            console.log(error)
        })
    }, [dateFromFilter, dateToFilter])


    useEffect(() => {
        const requestBody = {};
        if (dateFromFilter) {
            requestBody.startDate = dateFromFilter;
        }
        if (dateToFilter) {
            requestBody.endDate = dateToFilter;
        }
        if (competitionFilterId) {
            requestBody.cid = competitionFilterId
        }

        fetch(`${BASE_URL}/tracking/matches`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJhcGkuZGV2Lnh5ei5jb20iLCJhdWQiOiJ4eXouY29tIiwic3ViIjoiMTJiNTBhNDgtNWU5ZS00MzNmLTgyNmMtMTJhNmQ3MmM5N2NkIiwiaWF0IjoxNzExNDU2OTEzLCJleHAiOjE3NDI1NjA5MTMsInBybSI6IjMwZDQyNzg2ZGMyNTU3MDBkZDkzNzViMWQ4ZDYwN2E2MzBhYzViMzJhNWU1MjUxODQ2ZDQyNzE1YjBlN2Y3YTllZjczZjAzZDk1MzI3OGQ5NTdjOTVmOGE2YTVkZjk1MTM3MDc4ZTY4ODNlNTU3MjUzODczZjViYjNjMDZiNzZmIn0.msUlenclhnnuqwdU9R5HL32rYnUVmdyITkPlzSWDcio'
            },
            body: JSON.stringify(requestBody)
        })
            .then(response => response.json())
            .then(data => {
                setMatchData(data.data.users);
            })
            .catch(error => console.error('Error fetching matches for dropdown:', error));
    }, [dateFromFilter, dateToFilter, competitionFilterId]);

    const calculateTotals = (data) => {
        return {
            totalPosts: data.reduce((acc, row) => acc + Number(row.feeds_count || 0), 0),
            totalUniquePlayerVisits: data.reduce((acc, row) => acc + Number(row.player_unique_visits || 0), 0),
            totalPlayerTotalVists: data.reduce((acc, row) => acc + Number(row.player_total_views || 0), 0),
            totalUniqueFeedViews: data.reduce((acc, row) => acc + Number(row.feed_views || 0), 0),
            totalFeedViews: data.reduce((acc, row) => acc + Number(row.feed_total_views || 0), 0),
            totalEngagement: data.reduce((acc, row) => acc + Number(row.engagement || 0), 0).toFixed(2),
        };
    };
    const totals = calculateTotals(filteredData);

    const columns = [
        { field: 'title', headerName: 'Name', flex: 1 },
        { field: 'feeds_count', headerName: 'Posts', flex: 1, type: 'number' },
        { field: 'player_unique_visits', headerName: 'Unique Player Visits', flex: 1, type: 'number' },
        { field: 'player_total_views', headerName: 'Total Player Visits', flex: 1, type: 'number' },
        { field: 'feed_views', headerName: 'Unique Views', flex: 1, type: 'number' },
        { field: 'feed_total_views', headerName: 'Total Views', flex: 1, type: 'number' },
        { field: 'engagement', headerName: 'Engagement', flex: 1, type: 'number' },
        { field: 'rating_count', headerName: 'Number of Ratings', flex: 1, type: 'number' },
        { field: 'avg_rating', headerName: 'Average Rating', flex: 1, type: 'number' },
    ];

    const handleSearch = (event) => {
        const value = event.target.value.toLowerCase();
        setSearchText(value);
        const filteredRows = playerData.filter((row) => {
            return Object.values(row).some((field) =>
                String(field).toLowerCase().includes(value)
            );
        });
        setFilteredData(filteredRows);
    };
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const options = { day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', minute: 'numeric' };
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const formattedMonth = date.getUTCMonth();
        const formattedDate = date.getUTCDate();
        const formattedYear = date.getUTCFullYear();
        const formattedTime = (date.getUTCHours() % 12 || 12) + ':' + (date.getUTCMinutes() < 10 ? '0' : '') + date.getUTCMinutes() + ' ' + (date.getUTCHours() < 12 ? 'am' : 'pm') + ' IST';
        return months[formattedMonth] + ' ' + formattedDate + ' ' + formattedYear + ' ' + formattedTime;
    };
    return (


        <Component>
            <div class="page-body">
                <div class="container-fluid">
                    <div class="page-header">
                        <div class="row">
                            <div className="col-sm-12">
                                <div className="d-flex align-items-center ">
                                    <IconButton style={{ background: '#FFFFFF' }} component={Link} to="/content-dashboard">
                                        <ArrowBackIcon />
                                    </IconButton>
                                    <h3 style={{ marginLeft: '20px' }}>Player Dashboard</h3>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div class="container-fluid">
                    <div class="row">


                        <div class="col-sm-12">
                            <div class="card">
                                <div class="card-body">
                                    <Grid container spacing={2} sx={{ marginBottom: '20px' }}>
                                        <Grid item xs={12} sm={6} md={4}>
                                            <Autocomplete
                                                id="player-name-filter"
                                                options={playerNames}
                                                value={playerNameFilter}
                                                onChange={(event, newValue) => setPlayerNameFilter(newValue)}
                                                renderInput={(params) => <TextField {...params} label="Filter by Name" variant="outlined" />}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4}>
                                            <TextField
                                                label="From Date"
                                                type="date"
                                                variant="outlined"
                                                fullWidth
                                                value={dateFromFilter}
                                                onChange={(e) => setDateFromFilter(e.target.value)}
                                                InputLabelProps={{ shrink: true }}
                                                sx={{ backgroundColor: 'white', borderRadius: '8px' }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4}>
                                            <TextField
                                                label="To Date"
                                                type="date"
                                                variant="outlined"
                                                fullWidth
                                                value={dateToFilter}
                                                onChange={(e) => setDateToFilter(e.target.value)}
                                                InputLabelProps={{ shrink: true }}
                                                sx={{ backgroundColor: 'white', borderRadius: '8px' }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6}>
                                            <Autocomplete
                                                id="competition-filter"
                                                options={competitionData}
                                                getOptionLabel={(option) => `${option.cid || ' '} - ${option.title || ' '} - ${option.game_format || ''}`}
                                                value={competitionFilter}
                                                onChange={(event, newValue) => {
                                                    setCompetitionFilterId(newValue?.cid);
                                                    setCompetitionFilter(newValue);
                                                }}
                                                renderInput={(params) => {
                                                    return <TextField {...params} label="Select Competition" variant="outlined" fullWidth />
                                                }}
                                                sx={{ backgroundColor: 'white' }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4}>
                                            <Autocomplete
                                                id="match-filter"
                                                options={[{ id: 'all', name: 'All' }, ...matchData]}
                                                getOptionLabel={(option) => option.title + ", " + option.subtitle + ", " + formatDate(option.date_start_ist)}
                                                value={matchFilter}
                                                onChange={(event, newValue) => {
                                                    setMatchFilter(newValue);
                                                }}
                                                renderInput={(params) => <TextField {...params} label="Select Match" variant="outlined" />}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={8}>
                                            <TextField
                                                fullWidth
                                                label="Search"
                                                variant="outlined"
                                                value={searchText}
                                                onChange={handleSearch}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Box
                                        sx={{
                                            backgroundColor: '#f9f9f9',
                                            border: '1px solid #ddd',
                                            padding: '16px',
                                            borderRadius: '8px',
                                            marginBottom: '20px'
                                        }}
                                    >
                                    <Grid container spacing={2}>
                                        <Grid item xs={6} sm={4}><Typography>Total Posts: {totals.totalPosts}</Typography></Grid>
                                        <Grid item xs={6} sm={4}><Typography>Total Unique visits: {totals.totalUniquePlayerVisits}</Typography></Grid>
                                        <Grid item xs={6} sm={4}><Typography>Total Visits: {totals.totalUniquePlayerVisits}</Typography></Grid>
                                        <Grid item xs={6} sm={4}><Typography>Total Unique Feed Views: {totals.totalUniqueFeedViews}</Typography></Grid>
                                        <Grid item xs={6} sm={4}><Typography>Total Feed Views: {totals.totalFeedViews}</Typography></Grid>
                                        <Grid item xs={6} sm={4}><Typography>Total Engagement: {totals.totalEngagement}</Typography></Grid>
                                    </Grid>
                                        
                                    </Box>

                                    {loading ? (
                                        <Box
                                            sx={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                height: "100%",
                                            }}
                                        >
                                            <CircularProgress />
                                        </Box>
                                    ) : (
                                        <DataGrid
                                            rows={filteredData}
                                            columns={columns}
                                            pageSize={25}
                                            rowsPerPageOptions={[25, 50, 100]}
                                            getRowId={(row) => row.pid}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </Component>

    )
}

export default Players
