import React from 'react'
import Component from '../../components/Component'
import { Container, Typography, Grid, Paper } from '@mui/material';
import { Link } from 'react-router-dom';
import {useSelector} from 'react-redux';


function Dashboard() {
  const userId = useSelector((state) => state.roleReducer.userId);
  console.log('userId', userId)
  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await axios.post(
  //         `${BASE_URL}/admin/authenticate`,
  //         {}, // Request body (empty in this case)
  //         {
  //           withCredentials: true, // Ensure cookies are included in the request
  //           headers: {
  //             'Content-Type': 'application/json',
  //           }
  //         }
  //       );
  //       if(response?.data?.statusCode == 10000){
  //         console.log('User is authenticated')
  //         setAuthenticated(true);
  //         return;
  //       }
  //       else{
  //       window.alert('User is not authenticated');
  //       window.location = '/login';

  //       }
        
  //     } catch (error) {
  //       console.error('Error fetching data:', error);
  //       window.alert('User is not authenticated');
  //       window.location = '/login';
  //     }
  //   };
  //   fetchData();
  // }, []); 
  if (!userId) {
    return null;
  }
  else{
  return (
    <div>
      <Component>
        <div class="page-body">
          <div class="container-fluid">
            <div class="page-header">
              <div class="row">

                <div class="col-sm-6">
                  <h3>Dashboard</h3>
                </div>
              </div>
            </div>
          </div>

          <div class="container-fluid">
            <div class="row">

              <div class="col-sm-12">
                <div class="card">
                  <div class="card-body" style={{ height: '75vh' }}>
                    <div className='row'>

                    </div>
                    <div class="row">
                      <Container maxWidth="lg" sx={{ marginTop: '30px' }}>
                        <Grid container spacing={3}>
                          <Grid id = "1" item xs={4}>
                            <Link to="/player-photo" style={{ textDecoration: 'none' }}>
                              <Paper elevation={3} sx={{ padding: '30px', textAlign: 'center', backgroundColor: '#2C3E50', color: '#FFFFFF' }}>
                                <img className='player-icon' src="images/player-image.png" alt="" />
                                <Typography variant="h5" gutterBottom>Player Images</Typography>
                              </Paper>
                            </Link>
                          </Grid>
                          <Grid id = "2" item xs={4}>
                            <Link to="/match-panel" style={{ textDecoration: 'none' }}>
                              <Paper elevation={3} sx={{ padding: '30px', textAlign: 'center', backgroundColor: '#2980B9', color: '#FFFFFF' }}>
                                <img className='player-icon' src="images/player.png" alt="" />
                                <Typography variant="h5" gutterBottom>Match Panel</Typography>
                              </Paper>
                            </Link>
                          </Grid>
                          <Grid id = "3" item xs={4}>
                            <Link to="/content-dashboard" style={{ textDecoration: 'none' }}>
                              <Paper elevation={3} sx={{ padding: '30px', textAlign: 'center', backgroundColor: '#27AE60', color: '#FFFFFF' }}>
                                <img className='player-icon' src="images/dashboard.png" alt="" />
                                <Typography variant="h5" gutterBottom>Content Dashboard</Typography>
                              </Paper>
                            </Link>
                          </Grid>
                          <Grid id = "4" item xs={4}>
                            <Link to="/new-user-dashboard" style={{ textDecoration: 'none' }}>
                              <Paper elevation={3} sx={{ padding: '30px', textAlign: 'center', backgroundColor: '#27AE60', color: '#FFFFFF' }}>
                                <img className='player-icon' src="images/newUsers.svg" alt="" />
                                <Typography variant="h5" gutterBottom>New User Dashboard</Typography>
                              </Paper>
                            </Link>
                          </Grid> 
                          <Grid id = "5" item xs={4}>
                            <Link to="/add-roles" style={{ textDecoration: 'none' }}>
                              <Paper elevation={3} sx={{ padding: '30px', textAlign: 'center', backgroundColor: '#27AE60', color: '#FFFFFF' }}>
                                <img className='player-icon' src="images/dashboard.png" alt="" />
                                <Typography variant="h5" gutterBottom>Add Roles</Typography>
                              </Paper>
                            </Link>
                          </Grid>
                          <Grid id = "6" item xs={4}>
                            <Link to="/competitions" style={{ textDecoration: 'none' }}>
                              <Paper elevation={3} sx={{ padding: '30px', textAlign: 'center', backgroundColor: '#27AE60', color: '#FFFFFF' }}>
                                <img className='player-icon' src="images/dashboard.png" alt="" />
                                <Typography variant="h5" gutterBottom>Competitions</Typography>
                              </Paper>
                            </Link>
                          </Grid>
                          <Grid id = "6" item xs={4}>
                            <Link to="/Icons" style={{ textDecoration: 'none' }}>
                              <Paper elevation={3} sx={{ padding: '30px', textAlign: 'center', backgroundColor: '#27AE60', color: '#FFFFFF' }}>
                                <img className='player-icon' src="images/dashboard.png" alt="" />
                                <Typography variant="h5" gutterBottom>Icons</Typography>
                              </Paper>
                            </Link>
                          </Grid>
                          <Grid id = "6" item xs={4}>
                            <Link to="/notifications" style={{ textDecoration: 'none' }}>
                              <Paper elevation={3} sx={{ padding: '30px', textAlign: 'center', backgroundColor: '#27AE60', color: '#FFFFFF' }}>
                                <img className='player-icon' src="images/dashboard.png" alt="" />
                                <Typography variant="h5" gutterBottom>Notifications</Typography>
                              </Paper>
                            </Link>
                          </Grid>
                          <Grid id = "6" item xs={4}>
                            <Link to="/new-installs" style={{ textDecoration: 'none' }}>
                              <Paper elevation={3} sx={{ padding: '30px', textAlign: 'center', backgroundColor: '#27AE60', color: '#FFFFFF' }}>
                                <img className='player-icon' src="images/dashboard.png" alt="" />
                                <Typography variant="h5" gutterBottom>New Installs</Typography>
                              </Paper>
                            </Link>
                          </Grid>
                          <Grid id = "6" item xs={4}>
                            <Link to="/copy-url" style={{ textDecoration: 'none' }}>
                              <Paper elevation={3} sx={{ padding: '30px', textAlign: 'center', backgroundColor: '#27AE60', color: '#FFFFFF' }}>
                                <img className='player-icon' src="images/dashboard.png" alt="" />
                                <Typography variant="h5" gutterBottom>Copy Url Logs</Typography>
                              </Paper>
                            </Link>
                          </Grid>
                          <Grid id = "6" item xs={4}>
                            <Link to="/inapp-notifications" style={{ textDecoration: 'none' }}>
                              <Paper elevation={3} sx={{ padding: '30px', textAlign: 'center', backgroundColor: '#27AE60', color: '#FFFFFF' }}>
                                <img className='player-icon' src="images/dashboard.png" alt="" />
                                <Typography variant="h5" gutterBottom>In App Notifications</Typography>
                              </Paper>
                            </Link>
                          </Grid>
                          <Grid id = "6" item xs={4}>
                            <Link to="/top-posts" style={{ textDecoration: 'none' }}>
                              <Paper elevation={3} sx={{ padding: '30px', textAlign: 'center', backgroundColor: '#27AE60', color: '#FFFFFF' }}>
                                <img className='player-icon' src="images/dashboard.png" alt="" />
                                <Typography variant="h5" gutterBottom>Top Posts</Typography>
                              </Paper>
                            </Link>
                          </Grid>
                        </Grid>
                        
                      </Container>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


      </Component>
    </div>
  )
}
}

export default Dashboard
