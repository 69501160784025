import React, { useState, useEffect } from 'react';
import Component from '../../components/Component'
import { BASE_URL, handleDynamicRequest } from '../../components/CommonExport';
import { TextField, Grid, Autocomplete, IconButton, Button, Card, Typography, Switch } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useDispatch, useSelector } from 'react-redux';
import { setUserId, setUserRole, setUserName, setJwtToken, setU_photo } from '../../Store/Role/action';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Highlights from '../Highlights/Highlights';
import Polls from '../Polls/Polls';
import Posts from '../Posts/Posts';
import Sponsors from '../Sponsors/Sponsors';
import Predictions from '../predictions/predictions';
import PlayerHighlights from '../Playerhiglights/playerHighlights';
import SendNotification from '../Notifications/notifications';
import axios from 'axios';

function MatchDetails() {

    const matches = useSelector((state) => state.matchReducer.matches);
    const [forceActiveState, setForceActiveState] = useState(false);
    const [forceActiveLoading, setForceActiveLoading] = useState(false);
    const [trendingPlayersState, setTrendingPlayersState] = useState(false)
    const [trendingPlayersLoading, setTrendingPlayersLoading] = useState(false)
    const [feedsStatus, setFeedsStatus] = useState(false)
    const [feedsStatusLoading, setFeedsStatusLoading] = useState(false)
    const [ratingStatus, setRatingStatus] = useState(false)
    const [ratingStatusLoading, setRatingStatusLoading] = useState(false)
    const [playerImagesLoading, setPlayerImagesLoading] = useState(false)

    const fetchForceActiveStatus = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/admin/force-active-status/${matches.match_id}`);
            const status = response.data.data[0].forced_active
            setForceActiveState(status)
        } catch (err) {
            console.log(err)
            window.alert('Error')
        }
    }
    const fetchTrendingActiveStatus = async () => {
        try{
            const response = await axios.get(`${BASE_URL}/admin/trending/status/${matches.match_id}`)
            setTrendingPlayersState(response.data.status)
        }catch(err){
            console.log(err)
            window.alert('Error')
        }
    }
    const toggleTrendingStatus = async () => {
        try{
            setTrendingPlayersLoading(true)
            await axios.put(`${BASE_URL}/admin/trending/toggle/${matches.match_id}`)
            setTrendingPlayersLoading(false)
            return true
        }catch(err){
            console.log(err)
            window.alert('Error')
        }
    }
    const fetchFeedsStatus = async () => {
        try{
            const result = await axios.get(`${BASE_URL}/admin/feeds/status/${matches.match_id}`)
            setFeedsStatus(result.data.data.status)
        }catch(err){
            console.log(err)
            window.alert('Error')
        }
    }
    const updateFeedsStatus = async () => {
        try{
            setFeedsStatusLoading(true)
            await axios.put(`${BASE_URL}/admin/feeds/status/${matches.match_id}`)
            setFeedsStatusLoading(false)
            return true
        }catch(err){
            console.log(err)
            throw err
        }
    }
    const fetchRatingStatus = async () => {
        try{
            const result = await axios.get(`${BASE_URL}/admin/rating/status/${matches.match_id}`)
            setRatingStatus(result.data.data.status)
        }catch(err){
            console.log(err)
            window.alert('Error')
        }
    }
    const updateRatingStatus = async () => {
        try{
            setRatingStatusLoading(true)
            await axios.put(`${BASE_URL}/admin/players/update-images/${matches.match_id}`)
            setRatingStatusLoading(false)
            return true
        }catch(err){
            console.log(err)
            throw err
        }
    }

    useEffect(() => {
        fetchForceActiveStatus()
        fetchTrendingActiveStatus()
        fetchFeedsStatus()
        fetchRatingStatus()
    }, []);


    const forceActive = () => {
        const changeForceActive = async () => {
            try {
                setForceActiveLoading(true)
                const response = await axios.put(`${BASE_URL}/admin/force-active/${matches.match_id}`, { enable: forceActiveState }, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                setForceActiveLoading(false)
            } catch (err) {
                console.log(err)
            }
        }
        changeForceActive()
    }

    const updatePlayerImages = async () => {
        try{
            setPlayerImagesLoading(true)
            await axios.put(`${BASE_URL}/admin/players/update-images/${matches.match_id}`)
            setPlayerImagesLoading(false)
            window.alert('Player images updated successfully')
        }catch(err){
            console.log(err)
            window.alert('Error')
        }
    }

    return (


        <Component>
            <div class="page-body">
                <div class="container-fluid">
                    <div class="page-header">
                        <div class="row">
                            <div className="col-sm-12">
                                <div className="d-flex align-items-center ">
                                    <IconButton style={{ background: '#FFFFFF' }} component={Link} to="/match-panel">
                                        <ArrowBackIcon />
                                    </IconButton>
                                    <h3 style={{ marginLeft: '20px' }}>Match Details</h3>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div class="container-fluid">
                    <div class="row">


                        <div class="col-sm-12">
                            <div class="card">

                                <Accordion style={{ marginBottom: 0 }} defaultExpanded>
                                    <AccordionSummary

                                        aria-controls="panel3-content"
                                        id="panel3-header"
                                    >
                                        <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                                            <h5 style={{ margin: 0, marginRight: '10px' }}>{matches.competition__title}</h5>
                                            <h7 style={{ margin: 0 }}>{matches.title}</h7>
                                        </div>
                                    </AccordionSummary>

                                    <AccordionDetails>

                                    </AccordionDetails>
                                </Accordion>

                                <Accordion style={{ marginBottom: 0, marginTop: 1 }} >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}

                                        aria-controls="panel3-content"
                                        id="panel3-header"
                                    >
                                        <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                                            <h6 style={{ margin: 0, marginRight: '10px' }}>Controls</h6>
                                        </div>
                                    </AccordionSummary>
                                    {/* 
                                    <AccordionDetails>
                                        {['Trending Players', 'Create Post', 'Rate Players'].map((title) => (
                                            <Card style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '10px', marginBottom: '10px', marginLeft: '100px', marginRight: '100px' }} key={title}>
                                                <Typography variant="h7">{title}</Typography>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <Typography variant="body1" style={{ marginRight: '10px' }}>Active</Typography>
                                                    <Switch />
                                                </div>
                                            </Card>
                                        ))}
                                    </AccordionDetails> */}
                                    <AccordionDetails>
                                        {/* {['Trending Players', 'Create Post', 'Rate Players'].map((title) => (
                                            <Card
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'space-between',
                                                    padding: '10px',
                                                    marginBottom: '10px',
                                                    marginLeft: '100px',
                                                    marginRight: '100px'
                                                }}
                                                key={title}
                                            >
                                                <Typography variant="h7">{title}</Typography>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <Typography variant="body1" style={{ marginRight: '10px' }}>Active</Typography>
                                                    {title === 'Trending Players' ? (
                                                        <Switch checked={matchStatus} onChange={changeTrending} />
                                                    ) : title === 'Create Post' ? (
                                                        <Switch checked={matchFeeds} onChange={changeFeeds} />
                                                    ) : (
                                                        <Switch checked={matchRating} onChange={changeRating} />
                                                    )}
                                                </div>
                                            </Card>
                                        ))} */}
                                        <Card
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                                padding: '10px',
                                                marginBottom: '10px',
                                                marginLeft: '100px',
                                                marginRight: '100px'
                                            }}
                                        >
                                            <Typography variant="h7">Force Active</Typography>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <Switch checked={forceActiveState} onChange={() => setForceActiveState(!forceActiveState)} />
                                                <Button onClick={forceActive}>{forceActiveLoading ? 'saving...' : 'Save'}</Button>
                                            </div>
                                        </Card>
                                        <Card
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                                padding: '10px',
                                                marginBottom: '10px',
                                                marginLeft: '100px',
                                                marginRight: '100px'
                                            }}
                                        >
                                            <Typography variant="h7">Trending Players</Typography>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <Switch checked={trendingPlayersState} onChange={() => setTrendingPlayersState(!trendingPlayersState)} />
                                                <Button onClick={toggleTrendingStatus}>{trendingPlayersLoading ? 'saving...' : 'Save'}</Button>
                                            </div>
                                        </Card>
                                        <Card
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                                padding: '10px',
                                                marginBottom: '10px',
                                                marginLeft: '100px',
                                                marginRight: '100px'
                                            }}
                                        >
                                            <Typography variant="h7">Feeds</Typography>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <Switch checked={feedsStatus} onChange={() => setFeedsStatus(!feedsStatus)} />
                                                <Button onClick={updateFeedsStatus}>{feedsStatusLoading ? 'saving...' : 'Save'}</Button>
                                            </div>
                                        </Card>
                                        <Card
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                                padding: '10px',
                                                marginBottom: '10px',
                                                marginLeft: '100px',
                                                marginRight: '100px'
                                            }}
                                        >
                                            <Typography variant="h7">Rating</Typography>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <Switch checked={ratingStatus} onChange={() => setRatingStatus(!ratingStatus)} />
                                                <Button onClick={updateRatingStatus}>{ratingStatusLoading ? 'saving...' : 'Save'}</Button>
                                            </div>
                                        </Card>
                                        <Card
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                                padding: '10px',
                                                marginBottom: '10px',
                                                marginLeft: '100px',
                                                marginRight: '100px'
                                            }}
                                        >
                                            <Typography variant="h7">Update Player Images</Typography>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <Button onClick={updatePlayerImages}>{playerImagesLoading ? 'updating...' : 'Update'}</Button>
                                            </div>
                                        </Card>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion style={{ marginBottom: 0, marginTop: 1 }} >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}

                                        aria-controls="panel3-content"
                                        id="panel3-header"
                                    >
                                        <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                                            <h6 style={{ margin: 0, marginRight: '10px' }}>Highlights</h6>
                                        </div>
                                    </AccordionSummary>

                                    <AccordionDetails>
                                        <Highlights />
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion style={{ marginBottom: 0, marginTop: 1 }} >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}

                                        aria-controls="panel3-content"
                                        id="panel3-header"
                                    >
                                        <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                                            <h6 style={{ margin: 0, marginRight: '10px' }}>Player Highlight</h6>
                                        </div>
                                    </AccordionSummary>

                                    <AccordionDetails>
                                        <PlayerHighlights />
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion style={{ marginBottom: 0, marginTop: 1 }} >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}

                                        aria-controls="panel3-content"
                                        id="panel3-header"
                                    >
                                        <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                                            <h6 style={{ margin: 0, marginRight: '10px' }}>Polls</h6>
                                        </div>
                                    </AccordionSummary>

                                    <AccordionDetails>
                                        <Polls />
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion style={{ marginBottom: 0, marginTop: 1 }} >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}

                                        aria-controls="panel3-content"
                                        id="panel3-header"
                                    >
                                        <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                                            <h6 style={{ margin: 0, marginRight: '10px' }}>Prediction Polls</h6>
                                        </div>
                                    </AccordionSummary>

                                    <AccordionDetails>
                                        <Predictions />
                                    </AccordionDetails>
                                </Accordion>


                                <Accordion style={{ marginBottom: 0, marginTop: 1 }} >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}

                                        aria-controls="panel3-content"
                                        id="panel3-header"
                                    >
                                        <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                                            <h6 style={{ margin: 0, marginRight: '10px' }}>Create Post</h6>
                                        </div>
                                    </AccordionSummary>

                                    <AccordionDetails>
                                        <Posts />
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion style={{ marginBottom: 0, marginTop: 1 }} >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}

                                        aria-controls="panel3-content"
                                        id="panel3-header"
                                    >
                                        <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                                            <h6 style={{ margin: 0, marginRight: '10px' }}>Sponsored Posts</h6>
                                        </div>
                                    </AccordionSummary>

                                    <AccordionDetails>
                                        <Sponsors />
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion style={{ marginBottom: 0, marginTop: 1 }} >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}

                                        aria-controls="panel3-content"
                                        id="panel3-header"
                                    >
                                        <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                                            <h6 style={{ margin: 0, marginRight: '10px' }}>Notifications</h6>
                                        </div>
                                    </AccordionSummary>

                                    <AccordionDetails>
                                        <SendNotification />
                                    </AccordionDetails>
                                </Accordion>


                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </Component>

    )
}

export default MatchDetails
