import React, { useState, useEffect, useRef } from 'react';
import Component from '../../components/Component'
import { BASE_URL, handleDynamicRequest } from '../../components/CommonExport';

import { TextField, Grid, Autocomplete, Button, TextareaAutosize, Menu, MenuItem, Radio, RadioGroup, FormControlLabel, FormControl, } from '@mui/material';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { DataGrid } from '@mui/x-data-grid';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { set } from 'date-fns';
import { error } from 'jquery';


function Highlights() {
    const [playerData, setPlayerData] = useState([]);
    const [teamData, setTeamData] = useState([]);
    const [matchData, setMatchData] = useState([]);
    const [searchText, setSearchText] = React.useState('');
    const [filteredData, setFilteredData] = React.useState(playerData);
    const playerNames = playerData?.map(player => player.name);
    const [playerNameFilter, setPlayerNameFilter] = useState('');
    const [playerNameFilterNew, setPlayerNameFilterNew] = useState('');
    const [teamNameFilter, setTeamNameFilter] = useState('');
    const [teamNameFilterNew, setTeamNameFilterNew] = useState('');
    const [dateFromFilter, setDateFromFilter] = useState('');
    const [dateToFilter, setDateToFilter] = useState('');
    const [selectedUser, setSelectedUser] = useState({});
    const [matchFilter, setMatchFilter] = useState(null);
    const [modal, setModal] = useState(false);
    const [modalEdit, setModalEdit] = useState(false);
    const [innings, setInnings] = useState(null);
    const [selectedWall, setSelectedWall] = useState('match');
    const [icons, setIcons] = useState([])
    const [formData, setFormData] = useState({
        matchFilter: null,
        tagOver: '',
        playerNameFilter: null,
        writeHighlight: '',
        teamId: null,
        playerId: null,
    });
    const matches = useSelector((state) => state.matchReducer.matches);
    const toggle = () => {
        getIcons();
        setModal(!modal)
    };
    const toggleEdit = () => {
        getIcons()
        setModalEdit(!modalEdit)
    };

    useEffect(() => {
        fetchData();
    }, [matches, teamData, playerData]);
    useEffect(() => {
        fetchData();
    }, [teamNameFilter, playerNameFilter]);

    const fetchData = async () => {
        if (matches?.match_id) {
            try {
                const requestBody = {};

                if (teamNameFilter && teamNameFilter !== undefined && teamNameFilter !== null) {
                    requestBody.tid = teamNameFilter.teamId;
                }
                if (playerNameFilter) {
                    const playerLatest = playerData.find((player) => player.name === playerNameFilter);
                    requestBody.player_id = playerLatest.player_id;
                }
                const response = await fetch(`${BASE_URL}/admin/insights/matches/${matches.match_id}`,
                    {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJhcGkuZGV2Lnh5ei5jb20iLCJhdWQiOiJ4eXouY29tIiwic3ViIjoiMTJiNTBhNDgtNWU5ZS00MzNmLTgyNmMtMTJhNmQ3MmM5N2NkIiwiaWF0IjoxNzExNDU2OTEzLCJleHAiOjE3NDI1NjA5MTMsInBybSI6IjMwZDQyNzg2ZGMyNTU3MDBkZDkzNzViMWQ4ZDYwN2E2MzBhYzViMzJhNWU1MjUxODQ2ZDQyNzE1YjBlN2Y3YTllZjczZjAzZDk1MzI3OGQ5NTdjOTVmOGE2YTVkZjk1MTM3MDc4ZTY4ODNlNTU3MjUzODczZjViYjNjMDZiNzZmIn0.msUlenclhnnuqwdU9R5HL32rYnUVmdyITkPlzSWDcio'
                        },
                        body: JSON.stringify(requestBody)
                    });
                const data = await response.json();

                if (data?.data?.insights) {
                    const transformedData = await Promise.all(data.data.insights.map(async (insight) => {
                        const team = teamData?.find(t => t.teamId === insight.tid);
                        const player = playerData?.find(p => Number(p.player_id) === insight.player_id);
                        return {
                            ...insight,
                            teamName: team ? team.teamName : 'Unknown Team',
                            playerName: player ? player.name : 'Unknown Player'
                        };
                    }));
                    setMatchData(transformedData);
                    setFilteredData(transformedData);
                } else {
                    console.error('Invalid data structure:', data);
                }
            } catch (error) {
                console.error('Error fetching matches for dropdown:', error);
            }
        }
    };

    useEffect(() => {
        // Prepare request body
        const requestBody = {
            match_id: matches.match_id
        };


        fetch(`${BASE_URL}/tracking/match-players`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJhcGkuZGV2Lnh5ei5jb20iLCJhdWQiOiJ4eXouY29tIiwic3ViIjoiMTJiNTBhNDgtNWU5ZS00MzNmLTgyNmMtMTJhNmQ3MmM5N2NkIiwiaWF0IjoxNzExNDU2OTEzLCJleHAiOjE3NDI1NjA5MTMsInBybSI6IjMwZDQyNzg2ZGMyNTU3MDBkZDkzNzViMWQ4ZDYwN2E2MzBhYzViMzJhNWU1MjUxODQ2ZDQyNzE1YjBlN2Y3YTllZjczZjAzZDk1MzI3OGQ5NTdjOTVmOGE2YTVkZjk1MTM3MDc4ZTY4ODNlNTU3MjUzODczZjViYjNjMDZiNzZmIn0.msUlenclhnnuqwdU9R5HL32rYnUVmdyITkPlzSWDcio'

            },
            body: JSON.stringify(requestBody)
        })
            .then(response => response.json())
            .then(data => {
                setPlayerData(data.data.users);

            })
            .catch(error => console.error('Error fetching table data:', error));
    }, []);



    const columns = [
        { field: 'innings', headerName: 'Innings', flex: 1 },
        { field: 'over', headerName: 'Over Tagged', flex: 1 },
        { field: 'playerName', headerName: 'Player Name', flex: 1 },
        { field: 'teamName', headerName: 'Team Name', flex: 1 },
        { field: 'title', headerName: 'Title', flex: 1 },
        { field: 'description', headerName: 'Desciption', flex: 1 },
        {
            field: 'type_url',
            headerName: 'Icon',
            flex: 1,
            renderCell: (params) => (
                <img
                    src={params.row.type_url}
                    alt="icon"
                    style={{ width: '24px', height: '24px' }}
                />
            )
        },
        {
            field: 'actions',
            headerName: 'Actions',
            flex: 1,
            renderCell: (params) => <ActionMenu {...params} fetchData={fetchData} />
        },

    ];

    const handleSearch = (event) => {
        const value = event.target.value.toLowerCase();
        setSearchText(value);
        const filteredRows = playerData.filter((row) => {
            return Object.values(row).some((field) =>
                String(field).toLowerCase().includes(value)
            );
        });
        setFilteredData(filteredRows);
    };
    async function getIcons() {
        try {
            const response = await axios.get(`${BASE_URL}/icons`)
            setIcons(response.data.data.icons || [])
        } catch (err) {
            console.log(err)
            return err
        }
    }

    const handleChange = (event, field) => {
        const newValue = event.target ? event.target.value : event;
        setFormData(prevFormData => ({
            ...prevFormData,
            [field]: newValue
        }));
        setSelectedUser(prevState => ({
            ...prevState,
            [field]: newValue
        }));
    };
    const handleChangeEdit = (event, fieldName) => {
        const { value } = event.target;

        // Update selectedUser based on the field name
        setSelectedUser(prevUser => ({
            ...prevUser,
            [fieldName]: value
        }));
    };

    const handleWallChange = (event) => {
        const value = event.target.value;
        console.log(value)
        setSelectedWall(value);
        if (value === 'match') {
            setFormData(prevFormData => ({ ...prevFormData, location: 'Match Wall' }));
            if (selectedUser && selectedUser.length > 0) {
                setSelectedUser(prevFormData => ({ ...prevFormData, location: 'Match Wall' }));
            }

        }
    };
    const handlePlayerChange = (event, newValue) => {

        if (selectedWall === 'player') {
            setFormData(prevFormData => ({ ...prevFormData, location: newValue }));
            setSelectedUser(prevFormData => ({ ...prevFormData, location: newValue }));
        }
    };


    const handleEdit = () => {
        console.log('selectedUser', selectedUser);
        const payload = JSON.stringify(selectedUser);
        fetch(`${BASE_URL}/admin/insights/${selectedUser.id}`, {
            method: 'PUT',
            headers: {
                'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJhcGkuZGV2Lnh5ei5jb20iLCJhdWQiOiJ4eXouY29tIiwic3ViIjoiMTJiNTBhNDgtNWU5ZS00MzNmLTgyNmMtMTJhNmQ3MmM5N2NkIiwiaWF0IjoxNzExNDU2OTEzLCJleHAiOjE3NDI1NjA5MTMsInBybSI6IjMwZDQyNzg2ZGMyNTU3MDBkZDkzNzViMWQ4ZDYwN2E2MzBhYzViMzJhNWU1MjUxODQ2ZDQyNzE1YjBlN2Y3YTllZjczZjAzZDk1MzI3OGQ5NTdjOTVmOGE2YTVkZjk1MTM3MDc4ZTY4ODNlNTU3MjUzODczZjViYjNjMDZiNzZmIn0.msUlenclhnnuqwdU9R5HL32rYnUVmdyITkPlzSWDcio',
                'content-type': 'application/json'
            },
            body: payload
        })
            .then(response => response.json())
            .then(data => {
                fetchData();
            })
            .catch(error => console.error('Error fetching table data:', error));
        toggleEdit();
    }

    const handleSubmit = () => {
        const formDataNew = new FormData();
        if (formData.teamId) {
            formDataNew.append('tid', formData.teamId);
        }
        formDataNew.append('description', formData.writeHighlight);
        formDataNew.append('type', 'TEXT');
        formDataNew.append('over', formData.tagOver);
        if (formData.playerId) {
            console.log(formData.playerId);
            formDataNew.append('player_id', formData.playerId);
        }
        formDataNew.append('mid', matches?.match_id);
        formDataNew.append('innings', innings?.inningsName);
        formDataNew.append('image', null);
        formDataNew.append('title', formData.title);
        formDataNew.append('type_url', formData.type_url);
        console.log(formDataNew);
        toggle();


        fetch(`${BASE_URL}/admin/insights`, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJhcGkuZGV2Lnh5ei5jb20iLCJhdWQiOiJ4eXouY29tIiwic3ViIjoiMTJiNTBhNDgtNWU5ZS00MzNmLTgyNmMtMTJhNmQ3MmM5N2NkIiwiaWF0IjoxNzExNDU2OTEzLCJleHAiOjE3NDI1NjA5MTMsInBybSI6IjMwZDQyNzg2ZGMyNTU3MDBkZDkzNzViMWQ4ZDYwN2E2MzBhYzViMzJhNWU1MjUxODQ2ZDQyNzE1YjBlN2Y3YTllZjczZjAzZDk1MzI3OGQ5NTdjOTVmOGE2YTVkZjk1MTM3MDc4ZTY4ODNlNTU3MjUzODczZjViYjNjMDZiNzZmIn0.msUlenclhnnuqwdU9R5HL32rYnUVmdyITkPlzSWDcio'
            },
            body: formDataNew
        })
            .then(response => response.json())
            .then(data => {
                if (data.statusCode == "10001") {
                    console.log(data.statusCode)
                    alert("Please enter the team Id")
                }
                fetchData();
            })
            .catch(error => console.error('Error fetching table data:', error));
    };

    useEffect(() => {

        setTeamData([{
            teamId: matches.teama__team_id,
            teamName: matches.teama__name
        }, {
            teamId: matches.teamb__team_id,
            teamName: matches.teamb__name

        }])

    }, []);

    const inningsOptions = [
        { id: 1, inningsName: '1st Innings' },
        { id: 2, inningsName: '2nd Innings' },
    ];

    const ActionMenu = (params) => {
        const [anchorEl, setAnchorEl] = useState(null);

        const handleClick = (event) => {
            setAnchorEl(event.currentTarget);
        };

        const handleClose = () => {
            setAnchorEl(null);
        };

        const handleEdit = () => {
            handleClose();
            toggleEdit();

            setSelectedUser(params.row);
            console.log(selectedUser)

        };

        const handleDelete = () => {
            handleClose();
            fetch(`${BASE_URL}/admin/insights/${params.row.id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJhcGkuZGV2Lnh5ei5jb20iLCJhdWQiOiJ4eXouY29tIiwic3ViIjoiMTJiNTBhNDgtNWU5ZS00MzNmLTgyNmMtMTJhNmQ3MmM5N2NkIiwiaWF0IjoxNzExNDU2OTEzLCJleHAiOjE3NDI1NjA5MTMsInBybSI6IjMwZDQyNzg2ZGMyNTU3MDBkZDkzNzViMWQ4ZDYwN2E2MzBhYzViMzJhNWU1MjUxODQ2ZDQyNzE1YjBlN2Y3YTllZjczZjAzZDk1MzI3OGQ5NTdjOTVmOGE2YTVkZjk1MTM3MDc4ZTY4ODNlNTU3MjUzODczZjViYjNjMDZiNzZmIn0.msUlenclhnnuqwdU9R5HL32rYnUVmdyITkPlzSWDcio'

                }
            })
                .then(response => response.json())
                .then(data => {
                    fetchData();

                })
                .catch(error => console.error('Error fetching table data:', error));
        };

        return (
            <div>
                <Button
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={handleClick}
                >
                    <MoreVertIcon />
                </Button>
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    <MenuItem onClick={handleEdit}>Edit</MenuItem>
                    <MenuItem onClick={handleDelete}>Delete</MenuItem>
                </Menu>

            </div>
        );
    };

    return (


        <>

            <div class="card-body">
                <Grid container spacing={2} sx={{ marginBottom: '20px' }}>
                    <Grid item xs={12} sm={6} md={6}>
                        <Autocomplete
                            id="team-name-filter"
                            options={teamData}
                            getOptionLabel={(option) => option.teamName}
                            value={teamNameFilter}
                            onChange={(event, newValue) => setTeamNameFilter(newValue)}
                            renderInput={(params) => <TextField {...params} label="Filter by Team" variant="outlined" />}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <Autocomplete
                            id="player-name-filter"
                            options={playerNames}
                            value={playerNameFilter}
                            onChange={(event, newValue) => setPlayerNameFilter(newValue)}
                            renderInput={(params) => <TextField {...params} label="Filter by Player" variant="outlined" />}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={10}>
                        <TextField
                            fullWidth
                            label="Search"
                            variant="outlined"
                            value={searchText}
                            onChange={handleSearch}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={2}>
                        <Button style={{ width: '100%', height: '100%' }} variant="contained" color="primary" onClick={toggle}>
                            Add Highlights
                        </Button>
                    </Grid>
                </Grid>
                {filteredData && filteredData.length > 0 && (
                    <DataGrid
                        rows={filteredData}
                        columns={columns}
                        pageSize={25}
                        rowsPerPageOptions={[25, 50, 100]}
                        getRowId={(row) => row.id}
                        getRowClassName={(params) =>
                            params.row.deleted_at ? "deleted-row" : ""
                        }
                    />
                )}

                <style>
                    {`
        .deleted-row {
            background-color: rgba(255, 0, 0, 0.2) !important;
        }
    `}
                </style>
            </div>

            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle}>Add Highlight</ModalHeader>
                <ModalBody>
                    <form className="form theme-form">
                        <Grid container spacing={4}>
                            <Grid item xs={12}>
                                <Autocomplete
                                    id="team-name-filter"
                                    options={inningsOptions}
                                    getOptionLabel={(option) => option?.inningsName}
                                    value={innings}
                                    onChange={(event, newValue) => setInnings(newValue)}
                                    renderInput={(params) => <TextField {...params} label="Innings" variant="outlined" />}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Tag Over"
                                    variant="outlined"
                                    placeholder="Tag Over"
                                    value={formData.tagOver}
                                    onChange={(event) => setFormData({ ...formData, tagOver: event.target.value })}
                                />
                            </Grid>
                            <Grid item xs={12} container justifyContent="center" alignItems="center">

                                <Grid item xs={12}>
                                    <Autocomplete
                                        id="team-name-filter"
                                        options={teamData}
                                        getOptionLabel={(option) => option?.teamName || ""}
                                        value={teamData.find(team => team.teamId === formData.teamId) || null}
                                        onChange={(event, newValue) => {
                                            setFormData({ ...formData, teamId: newValue?.teamId });
                                        }}
                                        renderInput={(params) => <TextField {...params} label="Filter by Team" variant="outlined" />}
                                        fullWidth
                                    />
                                </Grid>


                            </Grid>
                            <Grid item xs={12}>
                                <Autocomplete
                                    id="player-name-filter"
                                    options={playerData}
                                    getOptionLabel={(option) => option?.name}
                                    value={playerData.find(player => player.player_id === formData.playerId) || null}
                                    onChange={(event, newValue) => {
                                        setFormData({ ...formData, playerId: newValue?.player_id });
                                    }}
                                    renderInput={(params) => <TextField {...params} label="Select Player" variant="outlined" />}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Write Title"
                                    variant="outlined"
                                    placeholder="Write Title"
                                    value={formData.title}
                                    onChange={(event) => handleChange(event, 'title')}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Write Desciption"
                                    variant="outlined"
                                    placeholder="Write Highlight"
                                    value={formData.writeHighlight}
                                    onChange={(event) => handleChange(event, 'writeHighlight')}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth variant="outlined">
                                    <Autocomplete
                                        options={icons}
                                        getOptionLabel={(option) => option.title || ''}
                                        value={icons.find(icon => icon.logo_url === formData.type_url) || null}
                                        onChange={(event, newValue) => {
                                            const selectedIconUrl = newValue ? newValue.logo_url : '';
                                            setFormData({ ...formData, type_url: selectedIconUrl });
                                            console.log(formData)
                                        }}
                                        renderOption={(props, option) => (
                                            <li {...props} style={{ display: 'flex', alignItems: 'center' }}>
                                                <img
                                                    src={option.logo_url}
                                                    alt="icon"
                                                    style={{ width: '24px', height: '24px', marginRight: '8px' }}
                                                />
                                                {option.title}
                                            </li>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Icon"
                                                placeholder="Search Icon"
                                                variant="outlined"
                                                InputProps={{
                                                    ...params.InputProps,
                                                    startAdornment: formData.type_url && (
                                                        <img
                                                            src={formData.type_url}
                                                            alt="icon"
                                                            style={{ width: '24px', height: '24px', marginRight: '8px' }}
                                                        />
                                                    ),
                                                }}
                                            />
                                        )}
                                    />
                                </FormControl>
                            </Grid>

                        </Grid>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={handleSubmit}>Submit</Button>
                    <Button color="secondary" onClick={toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>


            <Modal isOpen={modalEdit} toggle={toggleEdit}>
                <ModalHeader toggle={toggleEdit}>Edit Highlight</ModalHeader>
                <ModalBody>
                    <form className="form theme-form">
                        <Grid container spacing={4}>
                            <Grid item xs={12}>
                                <Autocomplete
                                    id="team-name-filter"
                                    options={inningsOptions}
                                    getOptionLabel={(option) => option?.inningsName || ""}
                                    value={inningsOptions.find(inn => inn.inningsName === selectedUser.innings) || null}
                                    onChange={(event, newValue) => {
                                        setSelectedUser(prev => ({
                                            ...prev,
                                            innings: newValue?.inningsName
                                        }));
                                    }}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Innings" variant="outlined" />
                                    )}
                                    fullWidth
                                />
                            </Grid>


                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Tag Over"
                                    variant="outlined"
                                    placeholder="Tag Over"
                                    value={selectedUser.over}
                                    onChange={(event) => handleChange(event, 'over')}
                                />
                            </Grid>
                            <Grid item xs={12} container justifyContent="center" alignItems="center">

                                <Grid item xs={12}>
                                    <Autocomplete
                                        id="team-name-filter"
                                        options={teamData}
                                        getOptionLabel={(option) => option?.teamName || ""}
                                        value={teamData.find(team => team.teamId === selectedUser.tid) || null}
                                        onChange={(event) => {
                                            handleChange(event, 'team_id');
                                        }}
                                        renderInput={(params) => <TextField {...params} label="Filter by Team" variant="outlined" />}
                                        fullWidth
                                    />
                                </Grid>


                            </Grid>

                            <Grid item xs={12}>
                                <Autocomplete
                                    id="player-name-filter"
                                    options={playerData}
                                    getOptionLabel={(option) => option?.name}
                                    value={playerData.find(player => {
                                        return player.player_id == selectedUser.player_id;
                                    }) || null}
                                    onChange={(event) => {
                                        handleChange(event, 'player_id');
                                    }}
                                    renderInput={(params) => <TextField {...params} label="Select Player" variant="outlined" />}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Write Highlight"
                                    variant="outlined"
                                    placeholder="Write Highlight"
                                    value={selectedUser.description}
                                    onChange={(event) => handleChange(event, 'description')}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Write Title"
                                    variant="outlined"
                                    placeholder="Write Title"
                                    value={selectedUser.title}
                                    onChange={(event) => handleChange(event, 'title')}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth variant="outlined">
                                    <Autocomplete
                                        options={icons}
                                        getOptionLabel={(option) => option.title || ''}
                                        value={icons.find(icon => icon.logo_url === selectedUser.type_url) || null}
                                        onChange={(event, newValue) => {
                                            const selectedIconUrl = newValue ? newValue.logo_url : '';
                                            setFormData({ ...formData, type_url: selectedIconUrl });
                                        }}
                                        renderOption={(props, option) => (
                                            <li {...props} style={{ display: 'flex', alignItems: 'center' }}>
                                                <img
                                                    src={option.logo_url}
                                                    alt="icon"
                                                    style={{ width: '24px', height: '24px', marginRight: '8px' }}
                                                />
                                                {option.title}
                                            </li>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Icon"
                                                placeholder="Search Icon"
                                                variant="outlined"
                                                InputProps={{
                                                    ...params.InputProps,
                                                    startAdornment: formData.type_url && (
                                                        <img
                                                            src={formData.type_url}
                                                            alt="icon"
                                                            style={{ width: '24px', height: '24px', marginRight: '8px' }}
                                                        />
                                                    ),
                                                }}
                                            />
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={handleEdit}>Submit</Button>
                    <Button color="secondary" onClick={toggleEdit}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </>


    )
}

export default Highlights