import Dashboard from "../pages/Dashboard/Dashboard";
import Matches from "../pages/Matches/Matches";
import Players from "../pages/Players/Players";
import Highlights from "../pages/Highlights/Highlights";
import Table from "../pages/Table/Table";
import Users from "../pages/Users/User";
import PlayerPhoto from "../pages/PlayersPhoto/PlayerPhoto";
import ContentDashboard from "../pages/Content-Dashboard/ContentDashboard";
import MatchPanel from "../pages/MatchPanel/MatchPanel";
import MatchDetails from "../pages/MatchDetails/MatchDetails";
import AddRole from "../pages/addRoles/AddRoles";
import UsersTable from "../pages/newUserDashboard/newUser.js";
// import Login from "../pages/login/login.js";
import CompetitionsList from "../pages/competitions/editCompetitions/editCompetitions.js";
import Login from '../pages/Authentication/Login.js';
import AddCompetition from '../pages/competitions/addCompetitions/addCompetitions.js';
import competitionsSettings from "../pages/competitions/comepetitions.js";
import competitionTeams from "../pages/competitions/editCompetitions/editTeams.js";
import NoAccess from "../pages/Access/noAccess.js";
import Icons from "../pages/icons/icons.js";
import NotificationHistory from "../pages/Notifications/notification_history.js";
import NewInstalls from "../pages/new_installs/new_install.js";
import UrlHistory from "../pages/copy-url/copy-url.js";
import InappNotifications from "../pages/in-app-notifications/notifications.js";
import TopPosts from "../pages/topPosts/topPosts.js";


const authProtectedRoutes = [
    { id:1 ,path: "/content-dashboard", component: ContentDashboard },
    { id: 2, path: "/match-panel", component: MatchPanel },
    { id: 3 , path: "/user-dashboard", component: Users },
    { id: 4, path: "/player-dashboard", component: Players },
    { id: 5, path: "/match-dashboard", component: Matches },
    { id: 6, path: "/highlights", component: Highlights },
    { id: 7, path: "/player-photo", component: PlayerPhoto },
    { id: 8, path: "/match-details", component: MatchDetails },
    { id: 9, path: "/new-user-dashboard", component: UsersTable},
    { id: 10, path: "/add-roles", component: AddRole},
    {id: 11, path: '/competitions', component: competitionsSettings},
    {id: 12, path: "/competitions/edit", component: CompetitionsList},
    {id: 13, path: "/competitions/add", component: AddCompetition},
    {id: 14, path: "/competitions/edit/:cid", component: competitionTeams},
    {id: 15, path: "/icons", component: Icons},
    {id: 16, path: "/notifications", component: NotificationHistory},
    {id: 17, path: "/new-installs", component: NewInstalls},
    {id: 18, path: "/copy-url", component: UrlHistory},
    {id: 19, path: "/inapp-notifications", component: InappNotifications},
    {id: 20, path: "/top-posts", component: TopPosts}

];


const publicRoutes = [
    { path: "/dashboard", component: Dashboard },
    {path: '/dashboards', component: Dashboard},
    { path: "/login", component: Login},
    {path: "/", component: Dashboard},
    {path: "/access-denied", component: NoAccess}

];

export { authProtectedRoutes,  publicRoutes };
