import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import { BASE_URL } from '../../../components/CommonExport';
import Component from '../../../components/Component';
import { Box, Typography, CircularProgress, Paper, Button, Dialog, DialogTitle, DialogContent, TextField, DialogActions, IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link } from 'react-router-dom';
import './editTeams.css';

const CompetitionTeams = () => {
  // States for Teams
  const [teams, setTeams] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // States for Competition Details
  const [competition, setCompetition] = useState(null);
  const [compLoading, setCompLoading] = useState(false);
  const [compError, setCompError] = useState(null);
  const [compOpen, setCompOpen] = useState(false);
  const [compEditData, setCompEditData] = useState({
    short_title: '',
    logo1: '',
    logo2: '',
    color1: '',
    color2: '',
  });

  // States for editing team color
  const [open, setOpen] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [newColor, setNewColor] = useState('');

  // Extract competition id from URL (adjust extraction logic as needed)
  const compId = window.location.pathname.split('/').pop();
  const getTeams = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/admin/get-teams/${compId}`);
      setTeams(response.data.data.teams);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const getCompetitionDetails = async () => {
    setCompLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/admin/competitions/get_competition/${compId}`);
      const comp = response.data.data.competition;
      console.log(comp);
      setCompetition(comp);
      setCompEditData({
        short_title: comp.short_title,
        logo1: comp.logo1,
        logo2: comp.logo2,
        color1: comp.color1,
        color2: comp.color2,
      });
    } catch (error) {
      setCompError(error);
    } finally {
      setCompLoading(false);
    }
  };

  useEffect(() => {
    
    getTeams();
    getCompetitionDetails();
  }, [compId]);

  // Team Edit Handlers
  const handleOpenDialog = (team) => {
    setSelectedTeam(team);
    setNewColor(team.team_colour); // Set initial color value
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
    setSelectedTeam(null);
  };

  const handleColorChange = async () => {
    if (selectedTeam) {
      try {
        await axios.put(`${BASE_URL}/admin/update-team/${selectedTeam.tid}`, { color: newColor });
        setTeams((prevTeams) =>
          prevTeams.map((team) =>
            team.id === selectedTeam.id ? { ...team, team_colour: newColor } : team
          )
        );
        handleCloseDialog();
      } catch (error) {
        setError(error);
      }
    }
  };

  // Competition Edit Handlers
  const handleOpenCompetitionDialog = (competition) => {
    setCompEditData({
      short_title: competition.short_title,
      logo1: competition.logo1,
      logo2: competition.logo2,
      color1: competition.color1,
      color2: competition.color2,
    });
    setCompOpen(true);
  };

  const handleCloseCompetitionDialog = () => {
    setCompOpen(false);
  };

  const handleCompetitionUpdate = async () => {
    try {
      const response = await axios.put(
        `${BASE_URL}/admin/competitions/update-competition-details/${compId}`,
        compEditData
      );
      getCompetitionDetails()
      setCompOpen(false);
    } catch (error) {
      setCompError(error);
    }
  };

  // DataGrid Columns for Competition Details (only one row)
  const competitionColumns = [
    { field: 'short_title', headerName: 'Short Title', width: 150 },
    {
      field: 'logo1',
      headerName: 'Logo 1',
      width: 150,
      renderCell: (params) => (
        <img src={params.value} alt="Logo1" style={{ width: '50px', height: '50px' }} />
      ),
    },
    {
      field: 'logo2',
      headerName: 'Logo 2',
      width: 150,
      renderCell: (params) => (
        <img src={params.value} alt="Logo2" style={{ width: '50px', height: '50px' }} />
      ),
    },
    {
      field: 'color1',
      headerName: 'Color 1',
      width: 150,
      renderCell: (params) => (
        <div style={{ backgroundColor: params.value, width: '100%', height: '100%' }} />
      ),
    },
    {
      field: 'color2',
      headerName: 'Color 2',
      width: 150,
      renderCell: (params) => (
        <div style={{ backgroundColor: params.value, width: '100%', height: '100%' }} />
      ),
    },
    {
      field: 'edit',
      headerName: 'Edit',
      width: 200,
      renderCell: (params) => (
        <Button variant="outlined" onClick={() => handleOpenCompetitionDialog(params.row)}>
          Edit Competition
        </Button>
      ),
    },
  ];

  // DataGrid Columns for Teams
  const teamColumns = [
    { field: 'title', headerName: 'Title', width: 200 },
    { field: 'type', headerName: 'Type', width: 150 },
    {
        field: 'logo_url',
        headerName: 'Logo',
        width: 150,
        renderCell: (params) => (
          <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            <img src={params.value} alt="Logo" style={{ width: '50px', height: '50px' }} />
          </div>
        ),
      },
    {
      field: 'team_colour',
      headerName: 'Team Colour',
      width: 150,
      renderCell: (params) => (
        <div style={{ backgroundColor: params.value, width: '100%', height: '100%' }} />
      ),
    },
    {
      field: 'edit',
      headerName: 'Edit',
      width: 150,
      renderCell: (params) => (
        <Button variant="outlined" onClick={() => handleOpenDialog(params.row)}>
          Edit Color
        </Button>
      ),
    },
  ];

  return (
    <Component>
      <div className="page-body">
        <div className="container-fluid">
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <div className="d-flex align-items-center">
                  <IconButton style={{ background: '#FFFFFF' }} component={Link} to="/competitions/edit">
                    <ArrowBackIcon />
                  </IconButton>
                  <h3 style={{ marginLeft: '20px' }}>Edit Competition & Teams</h3>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Competition Details Section */}
        <div className="container-fluid">
          <Box className="competition-details-container" mb={2}>
            {compError && <Typography color="error">Error: {compError.message}</Typography>}
            {compLoading ? (
              <Box className="competition-details-loading">
                <CircularProgress />
              </Box>
            ) : competition ? (
              <Paper elevation={3} className="competition-details-paper" style={{ marginBottom: '20px' }}>
                <DataGrid
                  rows={[competition]}
                  columns={competitionColumns}
                  pageSize={1}
                  autoHeight
                  disableSelectionOnClick
                  getRowId={(row) => row.cid}
                  className="competition-teams-data-grid"
                />
              </Paper>
            ) : null}
          </Box>

          {/* Teams Section */}
          <Box className="competition-teams-container">
            <Paper elevation={3} className="competition-teams-paper">
              {error && <Typography color="error">Error: {error.message}</Typography>}
              {loading ? (
                <Box className="competition-teams-loading">
                  <CircularProgress />
                </Box>
              ) : (
                <DataGrid
                  rows={teams}
                  columns={teamColumns}
                  pageSize={10}
                  rowsPerPageOptions={[10, 25, 50]}
                  autoHeight
                  disableSelectionOnClick
                  getRowId={(row) => row.id}
                  className="competition-teams-data-grid"
                />
              )}
            </Paper>
          </Box>
        </div>

        {/* Team Edit Dialog */}
        <Dialog open={open} onClose={handleCloseDialog}>
          <DialogTitle>Edit Team Color</DialogTitle>
          <DialogContent>
            <TextField
              fullWidth
              label="Team Color"
              value={newColor}
              onChange={(e) => setNewColor(e.target.value)}
              margin="dense"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} color="secondary">
              Cancel
            </Button>
            <Button onClick={handleColorChange} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>

        {/* Competition Edit Dialog */}
        <Dialog open={compOpen} onClose={handleCloseCompetitionDialog}>
          <DialogTitle>Edit Competition Details</DialogTitle>
          <DialogContent>
            <TextField
              fullWidth
              label="Short Title"
              value={compEditData.short_title}
              onChange={(e) =>
                setCompEditData({ ...compEditData, short_title: e.target.value })
              }
              margin="dense"
            />
            <TextField
              fullWidth
              label="Logo 1"
              value={compEditData.logo1}
              onChange={(e) =>
                setCompEditData({ ...compEditData, logo1: e.target.value })
              }
              margin="dense"
            />
            <TextField
              fullWidth
              label="Logo 2"
              value={compEditData.logo2}
              onChange={(e) =>
                setCompEditData({ ...compEditData, logo2: e.target.value })
              }
              margin="dense"
            />
            <TextField
              fullWidth
              label="Color 1"
              value={compEditData.color1}
              onChange={(e) =>
                setCompEditData({ ...compEditData, color1: e.target.value })
              }
              margin="dense"
            />
            <TextField
              fullWidth
              label="Color 2"
              value={compEditData.color2}
              onChange={(e) =>
                setCompEditData({ ...compEditData, color2: e.target.value })
              }
              margin="dense"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseCompetitionDialog} color="secondary">
              Cancel
            </Button>
            <Button onClick={handleCompetitionUpdate} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </Component>
  );
};

export default CompetitionTeams;



