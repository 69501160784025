import React, { useState, useEffect } from 'react';
import { IconButton, TextField, Dialog, DialogActions, DialogContent, DialogTitle, Button, Switch, FormControlLabel, CircularProgress, Box } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import { BASE_URL } from '../../components/CommonExport';
import Component from '../../components/Component.js';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditIcon from '@mui/icons-material/Edit';
import { Link } from 'react-router-dom';

const InappNotification = () => {
    const [notifications, setNotifications] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [selectedNotification, setSelectedNotification] = useState(null);
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        getUrls();
    }, []);

    async function getUrls() {
        try {
            setLoading(true)
            const response = await axios.get(`${BASE_URL}/admin/notification/inapp`);
            setNotifications(response.data.data.result || []);
            setLoading(false)
        } catch (err) {
            console.log(err);
        }
    }

    const handleEditClick = (notification) => {
        setSelectedNotification(notification);
        setOpenModal(true);
    };

    const handleClose = () => {
        setOpenModal(false);
        setSelectedNotification(null);
    };

    const handleEdit = async () => {
        if (!selectedNotification) return;
        selectedNotification.isUpdate = true
        try {
            await axios.post(`${BASE_URL}/admin/notification/inapp`, selectedNotification);
            getUrls(); // Refresh data after update
            handleClose();
        } catch (err) {
            console.error('Error updating notification:', err);
        }
    };

    const handleChange = (e) => {
        setSelectedNotification({ ...selectedNotification, [e.target.name]: e.target.value });
    };

    const columns = [
        { field: 'title', headerName: 'Title', width: 150 },
        { field: 'critical', headerName: 'Critical', width: 100 },
        { field: 'text', headerName: 'Description', width: 150 },
        { field: 'current_version', headerName: 'Current Version', width: 100 },
        { field: 'max_valid_version', headerName: 'Max Allowed Version', width: 100 },
        { field: 'logo_url', headerName: 'Logo Url', width: 100 },
        { field: 'cta_title', headerName: 'CTA Title', width: 150 },
        { field: 'cta_link', headerName: 'CTA Link', width: 150 },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 100,
            renderCell: (params) => (
                <IconButton onClick={() => handleEditClick(params.row)} color="primary">
                    <EditIcon />
                </IconButton>
            ),
        },
    ];

    return (
        <Component>
            <div className="page-body">
                <div className="container-fluid">
                    <div className="page-header">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="d-flex align-items-center">
                                    <IconButton style={{ background: '#FFFFFF' }} component={Link} to="/">
                                        <ArrowBackIcon />
                                    </IconButton>
                                    <h3 style={{ marginLeft: '20px' }}>In App Notifications</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-body">
                                <div style={{ height: 400, width: '100%' }}>
                                {loading ? (
                                        <Box
                                            sx={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                height: "100%",
                                            }}
                                        >
                                            <CircularProgress />
                                        </Box>
                                    ) : (
                                    <DataGrid rows={notifications} columns={columns} pageSize={5} rowsPerPageOptions={[5]} />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <Dialog open={openModal} onClose={handleClose} fullWidth maxWidth="sm">
                    <DialogTitle>Edit Notification</DialogTitle>
                    <DialogContent>
                        {selectedNotification && (
                            <>
                                <TextField
                                    label="Title"
                                    name="title"
                                    value={selectedNotification.title}
                                    onChange={handleChange}
                                    fullWidth
                                    margin="dense"
                                />
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={Boolean(selectedNotification.critical)}
                                            onChange={(e) =>
                                                handleChange({
                                                    target: { name: "critical", value: e.target.checked },
                                                })
                                            }
                                            color="primary"
                                        />
                                    }
                                    label="Critical"
                                />
                                <TextField
                                    label="Description"
                                    name="text"
                                    value={selectedNotification.text}
                                    onChange={handleChange}
                                    fullWidth
                                    margin="dense"
                                />
                                <TextField
                                    label="Current Version"
                                    name="current_version"
                                    value={selectedNotification.current_version}
                                    onChange={handleChange}
                                    fullWidth
                                    margin="dense"
                                />
                                <TextField
                                    label="Max Allowed Version"
                                    name="max_valid_version"
                                    value={selectedNotification.max_valid_version}
                                    onChange={handleChange}
                                    fullWidth
                                    margin="dense"
                                />
                                <TextField
                                    label="Logo URL"
                                    name="logo_url"
                                    value={selectedNotification.logo_url}
                                    onChange={handleChange}
                                    fullWidth
                                    margin="dense"
                                />
                                <TextField
                                    label="CTA Title"
                                    name="cta_title"
                                    value={selectedNotification.cta_title}
                                    onChange={handleChange}
                                    fullWidth
                                    margin="dense"
                                />
                                <TextField
                                    label="CTA Link"
                                    name="cta_link"
                                    value={selectedNotification.cta_link}
                                    onChange={handleChange}
                                    fullWidth
                                    margin="dense"
                                />
                                
                            </>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="secondary">Cancel</Button>
                        <Button onClick={handleEdit} color="primary" variant="contained">Save</Button>
                    </DialogActions>
                </Dialog>
            </div>
        </Component>
    );
};

export default InappNotification;
